/* eslint-disable max-len */
import React from 'react';

const PromoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="75" height="68" viewBox="0 0 75 68">
    <defs>
      <filter id="prefix__a" width="146.4%" height="172.2%" x="-23.2%" y="-30.6%" filterUnits="objectBoundingBox">
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 1 0 0 0 0 0.00784313725 0 0 0 0 0.321568627 0 0 0 1 0" />
      </filter>
      <filter id="prefix__c" width="139.3%" height="161.1%" x="-19.6%" y="-25%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner1" />
        <feGaussianBlur in="shadowSpreadInner1" result="shadowBlurInner1" stdDeviation=".5" />
        <feOffset dy="-1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" result="shadowMatrixInner1" values="0 0 0 0 0.732506793 0 0 0 0 0.0689377106 0 0 0 0 0.283858871 0 0 0 1 0" />
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner2" />
        <feGaussianBlur in="shadowSpreadInner2" result="shadowBlurInner2" stdDeviation=".5" />
        <feOffset dy="-1" in="shadowBlurInner2" result="shadowOffsetInner2" />
        <feComposite in="shadowOffsetInner2" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner2" />
        <feColorMatrix in="shadowInnerInner2" result="shadowMatrixInner2" values="0 0 0 0 1 0 0 0 0 0.00392156863 0 0 0 0 0.317647059 0 0 0 1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixInner1" />
          <feMergeNode in="shadowMatrixInner2" />
        </feMerge>
      </filter>
      <filter id="prefix__d" width="154.5%" height="173.8%" x="-27.3%" y="-36.9%" filterUnits="objectBoundingBox">
        <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0 0 0 0 0 0.0521285726 0 0 0 0 0.0732886905 0 0 0 1 0" />
        <feOffset in="SourceAlpha" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="5" />
        <feColorMatrix in="shadowBlurOuter2" result="shadowMatrixOuter2" values="0 0 0 0 0 0 0 0 0 0.563123886 0 0 0 0 0.996078431 0 0 0 1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <filter id="prefix__f" width="145.5%" height="161.5%" x="-22.7%" y="-30.7%" filterUnits="objectBoundingBox">
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner1" />
        <feGaussianBlur in="shadowSpreadInner1" result="shadowBlurInner1" stdDeviation=".5" />
        <feOffset dy="-1" in="shadowBlurInner1" result="shadowOffsetInner1" />
        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner1" />
        <feColorMatrix in="shadowInnerInner1" result="shadowMatrixInner1" values="0 0 0 0 0.0496539572 0 0 0 0 0.127964647 0 0 0 0 0.529211957 0 0 0 1 0" />
        <feMorphology in="SourceAlpha" radius="1" result="shadowSpreadInner2" />
        <feGaussianBlur in="shadowSpreadInner2" result="shadowBlurInner2" stdDeviation="1.5" />
        <feOffset dy="-1" in="shadowBlurInner2" result="shadowOffsetInner2" />
        <feComposite in="shadowOffsetInner2" in2="SourceAlpha" k2="-1" k3="1" operator="arithmetic" result="shadowInnerInner2" />
        <feColorMatrix in="shadowInnerInner2" result="shadowMatrixInner2" values="0 0 0 0 0.0470588235 0 0 0 0 0.648738302 0 0 0 0 1 0 0 0 1 0" />
        <feMerge>
          <feMergeNode in="shadowMatrixInner1" />
          <feMergeNode in="shadowMatrixInner2" />
        </feMerge>
      </filter>
      <path id="prefix__b" d="M38.784 2.252c2.89 2.938 2.953 7.947.188 10.974l-.188.2C35.794 16.463 31.08 18 27.286 18h-.02c-.032 0-.063-.002-.095-.004l.115.004c-.051 0-.102-.002-.151-.007-.04-.003-.08-.008-.118-.014L27 17.976l-.038.006c-.032.005-.064.009-.097.011l-.036.003c-.032.002-.063.004-.095.004h-.02c-3.794 0-8.507-1.536-11.498-4.575-2.955-3.003-2.955-8.17 0-11.173 2.956-3.003 7.755-3.003 10.71 0l.026.026.022.024c.388.427.729.902 1.027 1.423.296-.52.637-.996 1.025-1.423l.022-.024.026-.026c2.955-3.003 7.754-3.003 10.71 0zM17.64 4.716c-1.616 1.64-1.616 4.6-.003 6.243l.003.003c1.892 1.922 4.891 3.114 7.554 3.454l-.04.444c.067-.683.112-1.38.131-2.076-.015-.506-.016-1.014-.003-1.52-.017-.676-.065-1.345-.146-1.992-.255-2.053-.816-3.638-1.663-4.585l.028.029c-1.616-1.643-4.245-1.643-5.861 0zm12.886-.03l.018-.02c-.857.944-1.425 2.537-1.682 4.606-.08.647-.129 1.316-.149 1.992.016.506.015 1.014.003 1.52.012.546.043 1.092.087 1.631 2.664-.339 5.663-1.53 7.555-3.453l.003-.004.149-.16c1.463-1.668 1.412-4.493-.152-6.082-1.606-1.633-4.213-1.643-5.832-.03z" />
      <path id="prefix__e" d="M10.313 41c-2.592 0-4.474-1.807-4.58-4.357l-.004-.226v-21.2l-1.146.002c-2.591 0-4.473-1.808-4.578-4.357L0 10.635V4.906C0 2.314 1.807.433 4.357.328l.226-.005h45.834c2.591 0 4.473 1.807 4.578 4.356l.005.227v5.73c0 2.591-1.807 4.473-4.357 4.578l-.226.005-1.147-.001v21.199c0 2.591-1.807 4.473-4.356 4.578l-.227.005H10.313zM25.78 15.218H9.166v21.199c0 .676.291 1.048.883 1.129l.132.013.132.004 15.468-.001V15.218zm20.052 0H29.218v22.344h15.47c.718 0 1.093-.327 1.14-.997l.005-.148V15.219zM25.781 3.76H4.583c-.718 0-1.094.328-1.14.998l-.006.148v5.73c0 .718.328 1.093.998 1.14l.148.005h21.198V3.76zm24.636 0H29.218v8.02l21.199.001c.718 0 1.094-.328 1.14-.997l.005-.149V4.906c0-.77-.376-1.146-1.145-1.146z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(10 3)">
      <use fill="#000" filter="url(#prefix__a)" xlinkHref="#prefix__b" />
      <use fill="#FF5C8F" xlinkHref="#prefix__b" />
      <use fill="#000" filter="url(#prefix__c)" xlinkHref="#prefix__b" />
      <g transform="translate(0 14)">
        <use fill="#000" filter="url(#prefix__d)" xlinkHref="#prefix__e" />
        <use fill="#8DDEFF" xlinkHref="#prefix__e" />
        <use fill="#000" filter="url(#prefix__f)" xlinkHref="#prefix__e" />
      </g>
    </g>
  </svg>
);

export default PromoIcon;
