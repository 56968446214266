import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '@emotion/styled';
import { breakpoints, color, area } from '../../../utils/style';
import StarsFull from './StarsRealFull';
import StarsEmpty from './StarsRealEmpty';
import { withdrawCommentsListUrl } from './Config';
import UserIcon from './StarIcons/UserIcon';
import Comments from '../../../img/comments.svg';
import IcomoonIcon from '../../shared/IcomoonIcon';

const Wrap = styled('div')`
  text-align: left;
  padding: ${area.blockPadding};
  @media (max-height: ${breakpoints.tl}) {
    @media (min-height: ${breakpoints.md}) {
      @media (max-width: ${breakpoints.md}) {
        padding: 0 20px;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    margin-bottom: 0;
    // padding: 20px 15px;
  }
  .reviews-list {
    list-style: none;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .li {
    display: inline-block;
    vertical-align: top;
    width: 24%;
    //padding: 0 0 0 15px;
    margin: 10px 0;
    :first-of-type {
      padding-left: 0;
    }
    @media (max-width: ${breakpoints.md}) {
      :nth-of-type(n) {
        padding-right: 0;
        padding-left: 0;
        margin: 15px 0;
      }
    }
    &:nth-of-type(4n + 0) {
      padding-right: 0;
    }
  }
  .h3 {
    display: flex;
    align-items: center;
    margin: 0;
    svg {
      flex: 0 0 49px;
    }
  }
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .star-holder,
  .user-details {
    display: inline-block;
    vertical-align: top;
  }
  .user-details {
    padding: 0 0 0 12px;
    width: calc(100% - 40px);
  }
  .star-holder {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    background-color: #37ccfb1c;

    .emoji-wrap {
      display: inline-block;
      background: ${color.transparentWhite1};
      width: 40px;
      height: 40px;
      padding: 4px;
      border-radius: 20px;
    }
    svg {
      background: ${color.transparentWhite1};
      border-radius: 20px;
    }
  }
  .user-name {
    text-transform: uppercase;
    line-height: 1;
    white-space: nowrap;
    display: block;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
  }

  .rating-date {
    display: contents;
  }
  .request-time {
    margin: 2px 0 0 0;
    opacity: 0.8;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: normal;
  }
  .button {
    background: none;
    border: none;
    opacity: 0.8;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.62;
    letter-spacing: normal;
    color: var(--white);
  }

  @media (max-width: ${breakpoints.lg}) {
    .li {
      &:nth-of-type(3n + 0) {
        padding-right: 40px;
      }
      &:nth-of-type(2n + 0) {
        padding-right: 0;
      }
    }
  }
  @media (max-width: ${breakpoints.md}) {
    & .user-name {
      font-family: Kanit;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
    .h3 {
      img {
        width: 33px;
      }
    }
    .li {
      width: 100%;
      padding-right: 0;
      &:nth-of-type(3n + 0) {
        padding-right: 0;
      }
      &:nth-of-type(2n + 0) {
        padding-right: 0;
      }
    }
    .request-time {
      float: right;
      margin-right: calc(50vw - 115px);

      @media (max-height: ${breakpoints.tl}) {
        @media (min-height: ${breakpoints.md}) {
          @media (max-width: ${breakpoints.md}) {
            margin-right: calc(50vw + 100px);
          }
        }
      }
      @media (max-width: ${breakpoints.xs}) {
        margin-right: 0;
      }
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    padding: 30px 0;
    h3 span {
      padding-left: 20px;
    }
  }
`;

const RatingHolder = styled('div')`
  float: left;
  margin: 2px 10px 3px 0;
  position: relative;
  font-size: 20px;
  height: 18px;
  width: 80px;
  .rating {
    width: 140px;
    height: 28px;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    span {
      width: 16px;
      height: 16px;
      font-size: 12px;
      text-align: center;
      display: block;
      float: left;
    }
  }
  .rating-passive {
    z-index: 0;
  }
  .rating-active {
    z-index: 1;
  }
`;

const ReviewText = styled('div')`
  color: ${color.white};
  margin-top: 10px;
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  min-height: 110px;
  max-height: 110px;
  overflow: hidden;
`;

const LoadMore = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  padding: 0;

  opacity: 0.8;
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.62;
  letter-spacing: normal;
  color: #ffffff;
  margin-top: 15px;

  &:hover {
    color: #2da5fe;

    & > svg {
      & path {
        fill: #2da5fe !important;
      }
    }
  }

  &:active {
    opacity: 1;
  }

  :active,
  :focus {
    outline: none !important;
  }

  & > svg {
    margin-left: 10px;
  }

  & .mobile {
    display: none !important;
  }

  @media (max-width: ${breakpoints.md}) {
    font-weight: 500;
    color: #2da5fe;

    & .desktop {
      display: none !important;
    }

    & .mobile {
      display: inline-block !important;
    }

    & > svg {
      & path {
        fill: #2da5fe !important;
      }
    }
  }
`;

const loadCommentsData = async (page, pageSize, firstcomments, comments, setComments, setTotalPages) => {
  const loadMorePrepare = (data) => {
    const comments = data?.map((comment) => ({
      id: comment.id,
      wordpress_id: comment.id,
      rating: comment.rating,
      emoji: comment.emoji,
      author_name: comment.name,
      content: comment.comment,
      request_time: comment.request_time,
    }));
    return comments;
  };
  if (page === 1) {
    return;
  }
  const lastWordpressId = firstcomments.length > 0 ? firstcomments[firstcomments.length - 1].node.wordpress_id : false;
  const listingUrl = `${withdrawCommentsListUrl}/?per_page=${pageSize}&page=${page}&before_id=${lastWordpressId}`;
  const response = await fetch(listingUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (response.ok) {
    const totalPages = await response.headers.get('X-WP-TotalPages');
    setTotalPages(totalPages);
    const json = await response.json();
    const data = loadMorePrepare(json);
    comments.concat(data);
    setComments(comments.concat(data));
  }
};

const firstcommentsPrepare = (firstcomments) => {
  const firstcommentsPrepared = firstcomments.edges?.map((firstcomment) => ({
    id: firstcomment.node.id,
    wordpress_id: firstcomment.node.wordpress_id,
    rating: firstcomment.node.rating,
    emoji: firstcomment.node.emoji,
    author_name: firstcomment.node.name,
    content: firstcomment.node.comment,
    request_time: firstcomment.node.request_time,
  }));
  return firstcommentsPrepared;
};

// REFACTOR
// At least function shouldn't recreating every render. I'm sure here more mistakes.
const CommentsStaticLoadMore = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        allWordpressFreespinWithdrawcommentsfirst {
          edges {
            node {
              id
              emoji
              name
              rating
              request_time
              wordpress_id
              comment
            }
          }
        }
      }
    `}
    render={(data) => {
      const pageSize = 4;
      const [page, setPage] = useState(1);
      const [totalPages, setTotalPages] = useState(false);
      const firstcomments = data.allWordpressFreespinWithdrawcommentsfirst;
      const [comments, setComments] = useState(firstcommentsPrepare(firstcomments));
      useEffect(() => {
        loadCommentsData(page, pageSize, firstcomments, comments, setComments, setTotalPages);
      }, [page]);
      return (
        <Wrap>
          <div className="h3 with-icon">
            <img className="iconImage" src={Comments} alt="comments" width="44" height="44" />
            <span>ความคิดเห็นจากผู้เล่น</span>
          </div>
          {comments.length ? (
            <div className="reviews-list">
              {comments?.map((item) => (
                <div className="li" key={`${item.id}-w-comment`}>
                  <div className="name">
                    <UserIcon author={item.author_name} emoji={item.emoji} />
                    <div className="user-details">
                      <span className="user-name">{item.author_name}</span>
                      <div className="rating-date">
                        {item.rating && (
                          <RatingHolder>
                            <StarsFull n={item.rating} cssClass="rating rating-active" />
                            <StarsEmpty n="5" cssClass="rating rating-passive" />
                          </RatingHolder>
                        )}
                        <span className="request-time">{item.request_time}</span>
                      </div>
                    </div>
                  </div>
                  <ReviewText dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              ))}
              {(totalPages === false || totalPages > page) && (
                <div>
                  <LoadMore onClick={() => setPage(page + 1)}>
                    <span>ความคิดเห็นเพิ่มเติม</span>
                    <IcomoonIcon icon="arrow-down-simple" className="desktop" color="#fff" size={15} />
                    <IcomoonIcon icon="arrow-right" className="mobile" color="#fff" size={18} />
                  </LoadMore>
                </div>
              )}
            </div>
          ) : (
            <div>{totalPages * 1 === 0 ? 'ไม่มีความคิดเห็น. เป็นคนแรก' : 'กำลังโหลด ...'}</div>
          )}
        </Wrap>
      );
    }}
  />
);
export default CommentsStaticLoadMore;
