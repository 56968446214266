import React from 'react';
import IcomoonIcon from '../../shared/IcomoonIcon';

const Stars = ({ n, cssClass }) => {
  const arr = new Array(+n).fill(null);
  return (
    <div className={cssClass}>
      {arr.reduce(
        (prev, curr, i) =>
          prev.push(
            <span key={`Star-${i + 1}`}>
              <IcomoonIcon icon="star-full" color="#ff5c8f" size={12} alt="star full" />
            </span>,
          ) && prev,
        [],
      )}
    </div>
  );
};

export default Stars;
