import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import IcomoonIcon from '../../../shared/IcomoonIcon';
import { color } from '../../../../utils/style';

const UserIcon = ({ text }) => (
  <StaticQuery
    query={graphql`
      query {
        star: file(relativePath: { eq: "user-icon-new.png" }) {
          childImageSharp {
            fixed(width: 58) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <div className="star-holder">
        {data.star.childImageSharp.fixed ? (
          <IcomoonIcon icon="user" color={color.blue} size={20} alt="star icon" />
        ) : (
          <span>{text}</span>
        )}
      </div>
    )}
  />
);

UserIcon.propTypes = {
  text: PropTypes.string,
};

UserIcon.defaultProps = {
  text: '',
};

export default UserIcon;
