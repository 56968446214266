import React from 'react';
import IcomoonIcon from '../../shared/IcomoonIcon';

const Stars = ({ n, cssClass }) => {
  const arr = new Array(+n).fill(null);
  return (
    <div className={cssClass}>
      {arr.reduce(
        (prev, curr, i) =>
          prev.push(
            <span key={`Star-${i - 1}`}>
              <IcomoonIcon
                boxShadow="0 1px 7px 0 #ff0252, inset 0 -1px 2px 1px #bb1248, inset 0 -1px 2px 1px #ff0151"
                icon="star"
                color="#f30852"
                size={12}
                alt="star"
              />
            </span>,
          ) && prev,
        [],
      )}
    </div>
  );
};

export default Stars;
